html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "InterFace";
  font-weight: 300;
  src: url("./fonts/InterFace/woff/thin/InterFace_thin.woff2") format("woff2"),
    url("./fonts/InterFace/woff/thin/InterFace_thin.woff") format("woff");
}
@font-face {
  font-family: "InterFace";
  font-weight: 300;
  font-style: italic;
  src: url("./fonts/InterFace/woff/thin/InterFace_thin_italic.woff2")
      format("woff2"),
    url("./fonts/InterFace/woff/thin/InterFace_thin_italic.woff") format("woff");
}
@font-face {
  font-family: "InterFace";
  font-weight: 400;
  src: url("./fonts/InterFace/woff/regular/InterFace_regular.woff2")
      format("woff2"),
    url("./fonts/InterFace/woff/regular/InterFace_regular.woff") format("woff");
}
@font-face {
  font-family: "InterFace";
  font-weight: 400;
  font-style: italic;
  src: url("./fonts/InterFace/woff/regular/InterFace_regular_italic.woff2")
      format("woff2"),
    url("./fonts/InterFace/woff/regular/InterFace_regular_italic.woff")
      format("woff");
}
@font-face {
  font-family: "InterFace";
  font-weight: 600;
  src: url("./fonts/InterFace/woff/bold/InterFace_bold.woff2") format("woff2"),
    url("./fonts/InterFace/woff/bold/InterFace_bold.woff") format("woff");
}
@font-face {
  font-family: "InterFace";
  font-weight: 600;
  font-style: italic;
  src: url("./fonts/InterFace/woff/bold/InterFace_bold_italic.woff2")
      format("woff2"),
    url("./fonts/InterFace/woff/bold/InterFace_bold_italic.woff") format("woff");
}
@font-face {
  font-family: "InterFace";
  font-weight: 700;
  src: url("./fonts/InterFace/woff/extraBold/InterFace_extra_bold.woff")
    format("woff");
}
@font-face {
  font-family: "InterFace";
  font-weight: 700;
  font-style: italic;
  src: url("./fonts/InterFace/woff/extraBold/InterFace_extra_bold_italic.woff")
    format("woff");
}
@font-face {
  font-family: "Kabel";
  font-weight: 900;
  src: url("./fonts/kabel/kabel_std_black.woff2") format("woff2"),
    url("./fonts/kabel/kabel_std_black.woff") format("woff");
}


